export const SET_RANGE = 'SET_RANGE';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SORT = 'SET_SORT';
export const SET_COUNTY = 'SET_COUNTY';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_ACTIVE_DROPDOWN = 'SET_ACTIVE_DROPDOWN';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_FILTER_SORT_TO_DEFAULT = 'SET_FILTER_SORT_TO_DEFAULT';
export const SET_NOTICES_LIST_VIEW = 'SET_NOTICES_LIST_VIEW';
export const SET_NOTICES_FROM_SEARCH_PARAMS = 'SET_NOTICES_FROM_SEARCH_PARAMS';

export const setRange = (newRange) => {
  return { type: SET_RANGE, payload: newRange };
};

export const setFilters = (filterType, value, checked) => {
  return { type: SET_FILTERS, payload: { filterType, value, checked } };
};

export const setSort = (sortMethod) => {
  return { type: SET_SORT, payload: sortMethod };
};

export const setCounty = (county) => {
  return { type: SET_COUNTY, payload: county };
};

export const setSearch = (searchType, searchStr) => {
  return { type: SET_SEARCH, payload: { searchType, searchStr } };
};

export const setDateRange = (selection, customStart, customEnd) => {
  return { type: SET_DATE_RANGE, payload: { selection, customStart, customEnd } };
};

export const setActiveDropdown = (dropdownId) => {
  return { type: SET_ACTIVE_DROPDOWN, payload: dropdownId };
};

export const setFilterSortToDefault = () => {
  return { type: SET_FILTER_SORT_TO_DEFAULT };
};

export const setNoticesListView = (showList) => {
  return { type: SET_NOTICES_LIST_VIEW, payload: showList };
};

export const setNoticesFromSearchParams = (params) => {
  return { type: SET_NOTICES_FROM_SEARCH_PARAMS, payload: params };
};
