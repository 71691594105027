import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import {
  COUNTIES_OPTIONS,
  COUNTY_ID_TO_FRIENDLY_NAME,
} from "../../constants/counties";
import { Button, LinkLikeButton } from "../general/Button";
import { useNavigate } from "react-router-dom";
import {
  useUpdateEmailSubscription,
  useDeleteEmailSubscription,
} from "../../hooks/messagesAndRequests";
import { useRequest } from "redux-query-react";
import { useSelector } from "react-redux";
import { userSubscriptionCountiesSelector } from "../../selectors/entities";
import { getUserSubscriptionsQuery } from "../../actions/queries";

/*  
  This component allows users to manage their subscription preferences. 
  * Accessing through the user settings, this page will display an option to subscribe if the user doesn't have a subscription 
  or update their subscription in two ways; changing the counties they are subscribed to or unsubscribing.
  * Accessing through their email, this page will only display the subscription update options (changing counties / unsubscribing).
  Once the user has made changes to their subscription, they will redirected to the main page.
*/
export const EmailSubscriptionsPreferences = ({
  userEmail,
  redirectToMap,
  settingsPage,
}) => {
  const navigate = useNavigate();

  const [{ isFinished }] = useRequest(getUserSubscriptionsQuery({ userEmail }));
  const userSubscriptionCounties = useSelector(
    userSubscriptionCountiesSelector
  );

  const [updateSubscription] = useUpdateEmailSubscription();
  const [unsubscribe] = useDeleteEmailSubscription();

  const [selectedCounties, setSelectedCounties] = useState(
    userSubscriptionCounties
  );

  useEffect(() => {
    const userCounties = userSubscriptionCounties.map((county) => {
      return { label: COUNTY_ID_TO_FRIENDLY_NAME[county], value: county };
    });
    setSelectedCounties(userCounties);
  }, [userSubscriptionCounties]);

  const handleSelectCounties = (selectedCountiesList) => {
    setSelectedCounties(selectedCountiesList);
  };

  // If subscription exists, it updates the user's subscription with the new county selection
  // Otherwise, it creates a new subscription with the selected counties
  const handleUpdateSubscription = () => {
    const counties = selectedCounties.map((county) => county.value);
    updateSubscription({ userEmail, counties, isCurrentUser: true });
    redirectToMap && navigate("/");
  };

  const handleUnsubscribe = () => {
    const confirmedDelete = window.confirm(
      `Are you sure you want to unsubscribe this user from all emails?`
    );
    if (confirmedDelete) {
      unsubscribe({ userEmail, isCurrentUser: true });
      redirectToMap && navigate("/");
    }
  };

  // Disables the save/subscribe button if the user has not changed their county selection or has not selected any counties
  const checkCorrectCountySelection = () => {
    if (selectedCounties.length === 0) {
      return true;
    }
    const selectedCountiesArray = selectedCounties.map(
      (county) => county.value
    );

    return (
      JSON.stringify([...selectedCountiesArray].sort()) ===
      JSON.stringify([...userSubscriptionCounties].sort())
    );
  };

  if (!isFinished) {
    return <p>Loading...</p>;
  }

  // If user tries to access this page through their email and they have already deleted their subscription, display error message
  if (!settingsPage && userSubscriptionCounties.length === 0) {
    return (
      <p>
        No subscription found. Please visit{" "}
        <a href="https://aah-airmail.org/app/notices/">AirMail</a> to subscribe.
      </p>
    );
  }

  return (
    <>
      {userSubscriptionCounties.length > 0 ? (
        <>
          <h3>Update preferences</h3>
          <p style={{ marginBottom: "20px" }}>
            Update your county preferences for weekly notice summary emails:
          </p>
        </>
      ) : (
        <p style={{ marginBottom: "20px" }}>
          Subscribe to receive weekly email updates on the latest permits issued
          in specific counties.
        </p>
      )}

      <Multiselect
        id="counties-email-subscription"
        options={COUNTIES_OPTIONS}
        selectedValues={selectedCounties}
        onSelect={handleSelectCounties}
        onRemove={handleSelectCounties}
        displayValue="label"
        showCheckbox
        closeIcon="cancel"
        avoidHighlightFirstOption
        keepSearchTerm
        emptyRecordMsg="No counties found"
        style={{
          searchBox: {
            borderRadius: "0px",
            minHeight: "39px",
            marginTop: "5px",
          },
          inputField: { height: "20px" },
          chips: { background: "#00A7D9" },
        }}
      />

      <Button
        onClick={handleUpdateSubscription}
        disabled={checkCorrectCountySelection()}
        style={{ margin: "30px 0px" }}
      >
        {userSubscriptionCounties.length > 0 ? "Save Changes" : "Subscribe"}
      </Button>

      {userSubscriptionCounties.length > 0 && (
        <>
          <hr></hr>
          <h3>Unsubscribe</h3>
          <p style={{ margin: "20px 0px" }}>We're sorry to see you go!</p>
          <LinkLikeButton onClick={handleUnsubscribe}>
            Unsubscribe from AirMail
          </LinkLikeButton>
        </>
      )}
    </>
  );
};
