import { getFacilityNameFromNotice } from '../utils/helpers';
import moment from 'moment';

const API_ROOT = '/api';

export const loginQuery = values => ({
  url: `${API_ROOT}/auth/login`,
  options: { method: 'POST' },
  body: values,
  transform: resp => ({
    sessionExpirationTime: resp.expirationTime,
  }),
  update: {
    sessionExpirationTime: (prev, updated) => updated,
  },
});

export const getCurrentUserQuery = values => ({
  url: `${API_ROOT}/auth/current-user`,
  options: { method: 'GET' },
  body: values,
  transform: resp => ({
    currentUser: resp.user,
  }),
  update: {
    currentUser: (prev, updated) => updated,
  },
});

export const logoutQuery = () => ({
  url: `${API_ROOT}/auth/logout`,
  options: { method: 'POST' },
});

export const forgotPasswordQuery = username => ({
  url: `${API_ROOT}/auth/forgot-password`,
  options: { method: 'POST' },
  body: { username },
});

export const resetPasswordQuery = token => values => ({
  url: `${API_ROOT}/auth/reset-password`,
  options: { method: 'POST' },
  body: { password: values.password, token },
});

export const getPermitNoticesQuery = () => ({
  url: `${API_ROOT}/notices?filter=true&issuanceDateMin=${moment()
    .subtract(1, 'years')
    .format('YYYY-MM-DD')}`,
  options: { method: 'GET' },
  transform: resp => ({
    notices: (resp.notices || []).map(notice => ({
      ...notice,
      facilityName: getFacilityNameFromNotice(notice, false),
      facilityNameRaw: getFacilityNameFromNotice(notice, true),
    })),
  }),
  update: {
    notices: (prev, updated) => updated,
  },
});

export const getNumberOfAddressesNASACountiesQuery = ({ circle }) => ({
  url: `https://sedac.ciesin.columbia.edu/arcgis/rest/services/sedac/pesv330Sec/GPServer/pesv330Sec/execute?Input_Data=%7B%0D%0A+%22polygon%22%3A+%5B%0D%0A++%5B%0D%0A+++${circle}%0D%0A++%5D%0D%0A+%5D%2C%0D%0A+%22variables%22%3A+%22gpw-v4-population-count-rev10_2020%22%2C%0D%0A+%22statistics%22%3A+%5B%22SUM%22%5D%2C%0D%0A+%22requestID%22%3A+%220987654321%22%0D%0A%7D&f=pjson`,
  options: { method: 'GET' },
});

export const getNumberOfAddressesQuery = ({ lng, lat, radiusMiles }) => ({
  url: `${API_ROOT}/notices/number-of-addresses?lng=${lng}&lat=${lat}&radiusMiles=${radiusMiles}`,
  options: { method: 'GET' },
});

export const getNumberOfPlacesQuery = ({ lng, lat, radiusMiles }) => ({
  url: `${API_ROOT}/notices/number-of-places?lng=${lng}&lat=${lat}&radiusMiles=${radiusMiles}`,
  options: { method: 'GET' },
});

export const getAddressesQuery = ({ noticeId, lng, lat, radiusMiles }) => ({
  url: `${API_ROOT}/notices/addresses?noticeId=${noticeId}&lng=${lng}&lat=${lat}&radiusMiles=${radiusMiles}`,
  options: { method: 'GET' },
  transform: resp => ({
    addressesByMileRange: resp.addresses,
  }),
  update: {
    addressesByMileRange: (prev, updated) => ({
      ...(prev || {}),
      [`${radiusMiles}`]: updated,
    }),
  },
});

export const getElectedOfficialsQuery = ({ lng, lat }) => ({
  url: `${API_ROOT}/notices/elected-officials-from-point?lng=${lng}&lat=${lat}`,
  options: { method: 'GET' },
  transform: resp => ({
    legislators: resp.stateLegislators,
  }),
  update: {
    legislators: (prev, updated) => updated,
  },
});

export const uploadAddressesQuery = ({
  noticeObj,
  lng,
  lat,
  radiusMiles,
  includeStateLegislators,
  customAddresses,
}) => ({
  url: `${API_ROOT}/notices/upload-addresses?lng=${lng}&lat=${lat}&radiusMiles=${radiusMiles}&includeStateLegislators=${includeStateLegislators}`,
  options: { method: 'POST' },
  body: {
    noticeObj,
    customAddresses,
  },
  transform: ({
    groupId,
    inTestMode,
    numAddresses,
    importStatusIncomplete,
    templateTypeOptions,
  }) => ({
    stannp: {
      groupId,
      inTestMode,
      templateTypeOptions,
      importStatusIncomplete,
      numAddresses,
    },
  }),
  update: {
    stannp: (prev, updated) => ({
      ...prev,
      ...updated,
    }),
  },
});

export const createCampaignQuery = values => ({
  url: `${API_ROOT}/notices/create-campaign`,
  options: { method: 'POST' },
  body: values,
  transform: ({ cost, thumbnailImg, campaignId, stannpTemplateId }) => ({
    stannp: {
      cost,
      thumbnailImg,
      campaignId,
      stannpTemplateId,
    },
  }),
  update: {
    stannp: (prev, updated) => ({
      ...prev,
      ...updated,
      groupStatus: null,
      groupImportProgress: null,
    }),
  },
});

export const getGroupStatusQuery = groupId => ({
  url: `${API_ROOT}/notices/group-status`,
  options: { method: 'GET' },
  body: {
    groupId,
  },
  transform: ({ groupStatus, groupImportProgress }) => ({
    stannp: { groupStatus, groupImportProgress },
  }),
  update: {
    stannp: (prev, updated) => ({
      ...prev,
      ...updated,
    }),
  },
});

export const getCampaignBookingDatesQuery = values => ({
  url: `${API_ROOT}/notices/campaign-booking-dates`,
  options: { method: 'GET' },
  body: values,
  transform: ({ availableDates }) => ({
    stannp: {
      availableDates,
    },
  }),
  update: {
    stannp: (prev, updated) => ({
      ...prev,
      ...updated,
    }),
  },
});

export const approveCampaignQuery = values => ({
  url: `${API_ROOT}/notices/approve-campaign`,
  options: { method: 'POST' },
  body: values,
});

export const editNoticeDetailsQuery = values => {
  return {
    url: `${API_ROOT}/notices/edit-notice-details`,
    options: { method: 'POST' },
    body: { ...values },
    transform: resp => ({
      notices: resp.notice,
    }),
    update: {
      // After editing the notice, we want to update the notice in
      // the notices list. This prevents us from having to do another
      // full fetch of the notices.
      notices: (prev, updated) => {
        const prevCopy = [...prev];
        const noticeToUpdate = prevCopy.findIndex(notice => updated._id === notice._id);
        if (noticeToUpdate === -1) {
          return prev;
        }
        prevCopy[noticeToUpdate] = {
          ...prevCopy[noticeToUpdate],
          // We store the edited name as the main name on the object
          // This ensures that the edited name shows up as the facilityName
          // in the UI and in exports, campaigns, etc.
          facilityName: getFacilityNameFromNotice(updated, false),
          // The raw facility name is saved as well so we can have it as a reference
          facilityNameRaw: getFacilityNameFromNotice(updated, true),
          important: updated.important,
          publicActionDeadline: updated.publicActionDeadline,
        };
        return prevCopy;
      },
    },
  };
};

export const getUsersQuery = () => ({
  url: `${API_ROOT}/users/view-users`,
  options: { method: 'GET' },
  transform: resp => ({
    users: resp.users,
  }),
  update: {
    users: (prev, updated) => updated,
  },
});

export const deleteUserQuery = userId => ({
  url: `${API_ROOT}/users/delete-user`,
  options: { method: 'POST' },
  body: { userId },
  transform: () => ({
    users: null,
  }),
  update: {
    users: prev => {
      if (Array.isArray(prev)) {
        return prev.filter(user => user._id != userId);
      }
      return [];
    },
  },
});

export const addUserQuery = values => ({
  url: `${API_ROOT}/users/add-user`,
  options: { method: 'POST' },
  body: {
    ...values,
    username: values.email,
  },
  transform: resp => ({
    users: resp.user,
  }),
  update: {
    users: (prev, updated) => {
      if (Array.isArray(prev)) {
        return [...prev, updated];
      } else {
        return [updated];
      }
    },
  },
});

export const getAccountInfoQuery = values => ({
  url: `${API_ROOT}/users/account-info`,
  options: { method: 'GET' },
  transform: ({ email, firstName, lastName, county }) => ({
    accountInfo: {
      email,
      firstName,
      lastName,
      county,
    },
  }),
  update: {
    accountInfo: (prev, updated) => updated,
  },
});

export const updateCountyQuery = values => ({
  url: `${API_ROOT}/users/edit-county`,
  options: { method: 'POST' },
  body: values,
  transform: ({ county }) => ({
    accountInfo: {
      county,
    },
  }),
  update: {
    accountInfo: (prev, updated) => ({
      ...(prev || {}),
      ...updated,
    }),
  },
});

export const updateEmailSubscriptionQuery = values => ({
  url: `${API_ROOT}/subscriptions/update-subscription`,
  options: { method: 'POST' },
  body: values,
  transform: resp => ({
    subscriptions: resp.subscription,
    userSubscriptionCounties: resp.subscription,
  }),
  update: {
    subscriptions: (prev, updated) => {
      if (Array.isArray(prev)) {
        // checks if subscription already exists, if so, updates it, otherwise adds it
        const subscriptionExists = prev.find(subscription => subscription.email === updated.email);
        if (subscriptionExists) {
          return prev.map(subscription => {
            if (subscription.email === updated.email) {
              return updated;
            } else {
              return subscription;
            }
          });
        }
        return [...prev, updated];
      } else {
        return [updated];
      }
    },
    userSubscriptionCounties: (prev, updated) => {
      if (values.isCurrentUser) {
        return updated.counties;
      }
      return prev;
    },
  },
});

export const deleteEmailSubscriptionQuery = values => ({
  url: `${API_ROOT}/subscriptions/unsubscribe`,
  options: { method: 'POST' },
  body: values,
  transform: () => ({
    subscriptions: null,
    userSubscriptionCounties: null,
  }),
  update: {
    subscriptions: prev => {
      if (Array.isArray(prev)) {
        return prev.filter(subscription => subscription.email !== values.userEmail);
      }
      return [];
    },
    userSubscriptionCounties: (prev, updated) => {
      if (values.isCurrentUser) {
        return [];
      }
      return prev;
    },
  },
});

export const getAllEmailSubscriptionsQuery = () => ({
  url: `${API_ROOT}/subscriptions/`,
  options: { method: 'GET' },
  transform: resp => ({
    subscriptions: resp.subscriptions,
  }),
  update: {
    subscriptions: (prev, updated) => updated,
  },
});

export const getUserSubscriptionEmailQuery = ({ id }) => ({
  url: `${API_ROOT}/subscriptions/email?id=${id}`,
  options: { method: 'GET' },
  transform: resp => ({
    email: resp.email,
  }),
  update: {
    email: (prev, updated) => updated,
  },
});

export const getUserSubscriptionsQuery = ({ userEmail }) => ({
  url: `${API_ROOT}/subscriptions/counties?userEmail=${userEmail}`,
  options: { method: 'GET' },
  transform: resp => ({
    userSubscriptionCounties: resp.userSubscriptionCounties,
  }),
  update: {
    userSubscriptionCounties: (prev, updated) => updated,
  },
});

export const getPlacesOfInterestQuery = ({ bounds }) => ({
  url: `${API_ROOT}/places`,
  options: { method: 'POST' },
  body: { bounds },
  transform: ({ places }) => ({
    placesOfInterest: places,
  }),
  update: {
    placesOfInterest: (_, updated) => updated,
  },
  // Setting `force: true` ensures that switching between counties loads fresh data.
  // We don't want to store all places of interest in memory because it's a lot
  force: true,
});
