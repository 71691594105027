import React from 'react';
import styled from 'styled-components';
import { Wrapper } from './Account';
import { EmailSubscriptionsPreferences } from './EmailSubscriptionsPreferences';

const EmailSubscriptionWrapper = styled.div`
  width: 625px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const YourEmailSubscriptions = ({ userEmail }) => {
  return (
    <Wrapper>
      <div style={{ width: '1000px'}}>
        <h2 style={{ marginTop: '0px' }}>
          Email Subscriptions
        </h2>
        <EmailSubscriptionWrapper>
          <EmailSubscriptionsPreferences userEmail={userEmail} settingsPage={true} />
        </EmailSubscriptionWrapper>
      </div>
    </Wrapper>
  )
};