import { createSelector } from 'reselect';

export const tokenSelector = state => state.entities.token;
export const usersSelector = state => state.entities.users || [];

export const allSubscriptionsSelector = (state) => state.entities.subscriptions || [];
export const userSubscriptionCountiesSelector = (state) => state.entities.userSubscriptionCounties || [];
export const userSubscriptionEmailSelector = (state) => state.entities.email || '';

// Returns the time at which the session expires (admin) in milliseconds
export const sessionExpirationTimeSelector = state => state.entities.sessionExpirationTime;

export const currentUserSelector = state => state.entities.currentUser;
export const isAdminSelector = state =>
  state.entities.currentUser && state.entities.currentUser.admin;
export const accountInfoSelector = state => state.entities.accountInfo || {};

export const placesOfInterestSelector = state => state.entities.placesOfInterest || [];

export const noticesSelector = state => state.entities.notices || [];

export const stannpSelector = state => state.entities.stannp || {};
export const addressesByMileRangeSelector = state => state.entities.addressesByMileRange || {};

export const stateLegislatorsSelector = state => state.entities.legislators || [];

const secondParamSelector = (state, param2) => param2;
export const noticeByIdSelector = createSelector(
  [noticesSelector, secondParamSelector],
  (notices, id) => notices.find(notice => notice._id === id)
);

export const addressesSelector = createSelector(
  [addressesByMileRangeSelector, secondParamSelector],
  (addressesByMileRange, rangeInMiles) => addressesByMileRange[`${rangeInMiles}`] || []
);
