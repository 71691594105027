import moment from 'moment';
import { MAX_DESKTOP_WIDTH } from '../constants/cssVars';
import {
  GENERAL_MISC,
  GENERIC_FACILITY_NAME_BY_CATEGORY,
  PROGRAM_AREA_TO_FRIENDLY_NAME,
  NOTICE_TYPE_TO_FULL_NAME,
  PUBLIC_ACTION_TO_FULL_NAME,
} from '../constants/notices';

export const statusIsGood = status => status >= 200 && status < 300;
export const statusIsUnauthorized = status => status === 401;

export const stringIsEmptyOrNull = str => str == null || str === '';

export const getSlugFromName = str => (str ? str.toLowerCase().split(' ').join('_') : null);

export const getLocalStorageItem = key => {
  try {
    const result = localStorage.getItem(key);
    return result;
  } catch {
    return null;
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // since we only use this as a backup to in-memory state, don't need additional action on error
  }
};

// Connects two items by "&", and 3+ items by "__, __, ... __, & __"
export const prettyEnglishList = (strList, allLowerCase) => {
  if (!strList || strList.length === 0) {
    return null;
  }
  if (strList.length === 1) {
    return strList[0];
  }
  // make a new list where all but the first item are converted to lowercase
  const newList = strList.map((item, idx) => {
    if (idx === 0 && !allLowerCase) {
      return item;
    }
    return item.toLowerCase();
  });
  const last = newList.pop();
  return newList.join(', ') + (newList.length > 1 ? ',' : '') + ' & ' + last;
};

export const getMetersFromMiles = miles => miles * 1609.34;

export const getMonthFromIndex = (index, inSpanish) => {
  switch (index) {
    case 0:
      return inSpanish ? 'enero' : 'January';
    case 1:
      return inSpanish ? 'febrero' : 'February';
    case 2:
      return inSpanish ? 'marzo' : 'March';
    case 3:
      return inSpanish ? 'abril' : 'April';
    case 4:
      return inSpanish ? 'mayo' : 'May';
    case 5:
      return inSpanish ? 'junio' : 'June';
    case 6:
      return inSpanish ? 'julio' : 'July';
    case 7:
      return inSpanish ? 'agosto' : 'August';
    case 8:
      return inSpanish ? 'septiembre' : 'September';
    case 9:
      return inSpanish ? 'octubre' : 'October';
    case 10:
      return inSpanish ? 'noviembre' : 'November';
    case 11:
      return inSpanish ? 'diciembre' : 'December';
    default:
      return '';
  }
};

export const onMobile = () => {
  const mobileSystemDetected =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const mobileScreenWidth = window.innerWidth < MAX_DESKTOP_WIDTH;
  return mobileSystemDetected || mobileScreenWidth;
};

/**
 * Get the facility name from a notice object. If there is no facility name,
 * use the first category's name
 * If there are no categories, use 'industry facility'
 * If ignoreEdited is set to true, will return the facility name as scraped,
 * without any user edits
 * @param {any} notice
 * @param {boolean} ignoreEdited
 * @returns the facility name given a notice object
 */
export const getFacilityNameFromNotice = (notice, ignoreEdited) => {
  // If we have a display name override, use that
  if (!stringIsEmptyOrNull(notice.facilityNameEdited) && !ignoreEdited) {
    return notice.facilityNameEdited;
  }
  // If the name exists, just return it
  if (!stringIsEmptyOrNull(notice.facilityName)) {
    return notice.facilityName;
  }
  // Otherwise, use a generic name by the notice's first non-general/misc category, or return 'Industrial Facility'
  const nonGeneralMiscCode =
    Array.isArray(notice.industryTypeCodes) &&
    notice.industryTypeCodes.find(codeObj => codeObj.category !== GENERAL_MISC);
  const categoryName = nonGeneralMiscCode
    ? GENERIC_FACILITY_NAME_BY_CATEGORY[nonGeneralMiscCode.category]
    : null;
  return categoryName || 'Industrial Facility';
};

// Given a notice, return the unique categories the notice is associated with by industry type codes
export const getCategoriesForNotice = notice => {
  if (!Array.isArray(notice.industryTypeCodes)) {
    return [];
  }
  return notice.industryTypeCodes.reduce((list, codeObj) => {
    // Add a category to the list if it isn't there already
    if (!list.includes(codeObj.category)) {
      list.push(codeObj.category);
    }
    return list;
  }, []);
};

export const prepNoticeObjForExport = noticeObj => {
  const exportNoticeObj = { ...noticeObj };
  delete exportNoticeObj._id;
  delete exportNoticeObj.__v;
  delete exportNoticeObj.fromDate;
  delete exportNoticeObj.toDate;
  if (exportNoticeObj.location) {
    delete exportNoticeObj.location.type;
  }
  delete exportNoticeObj.permitNumbers;
  delete exportNoticeObj.datesNoticeMailed;
  delete exportNoticeObj.noticeUrls;
  delete exportNoticeObj.methodLocationFound;
  delete exportNoticeObj.facilityNameEdited;
  delete exportNoticeObj.facilityNameRaw;
  return exportNoticeObj;
};

export const flattenObj = (obj, specialHandler) => {
  // The object which contains the final result
  let result = {};
  // loop through the object "obj"
  for (const i in obj) {
    // recursively handle special properties
    const specialHandlerResult = specialHandler(i, obj[i]);
    if (specialHandlerResult) {
      const tempResult = specialHandlerResult;
      if (typeof tempResult === 'object') {
        const temp = flattenObj(tempResult, specialHandler);
        for (const j in temp) {
          // Store temp in result
          result[i + '.' + j] = temp[j];
        }
      } else {
        result[i] = specialHandlerResult;
      }
    } else if (Array.isArray(obj[i])) {
      result[i] = Object.values(obj[i]).join(', ');
    } else if (typeof obj[i] === 'object') {
      const temp = flattenObj(obj[i], specialHandler);
      for (const j in temp) {
        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    }
    // Else store obj[i] in result directly
    else {
      result[i] = obj[i];
    }
  }
  return result;
};

export const flattenNoticeSpecialProperties = (objKey, objProperty) => {
  if (
    [
      'dateNoticeMailed',
      'applicationReceivedDate',
      'issuanceDate',
      'publicActionDeadline',
    ].includes(objKey)
  ) {
    return objProperty ? moment(new Date(objProperty)).utc().format('M/D/YYYY') : '';
  } else if (objKey === 'industryTypeCodes') {
    return handleIndustryTypeCodes(objProperty);
  } else if (objKey === 'stateLegislators') {
    return handleStateLegislators(objProperty);
  } else if (objKey === 'programArea') {
    return PROGRAM_AREA_TO_FRIENDLY_NAME[objProperty];
  } else if (objKey === 'noticeType') {
    return NOTICE_TYPE_TO_FULL_NAME[objProperty];
  } else if (objKey === 'publicActions') {
    return objProperty.map(public_action => PUBLIC_ACTION_TO_FULL_NAME[public_action]).join(', ');
  } else {
    return null;
  }
};

export const handleIndustryTypeCodes = industryTypeCodes => {
  let naicsFullCodes = [];
  let naicsCodeNumbers = [];
  let naicsCodeNames = [];
  let sicFullCodes = [];
  let sicCodeNumbers = [];
  let sicCodeNames = [];
  industryTypeCodes.forEach(industryTypeCode => {
    if (industryTypeCode.codeSystem === 'NAICS') {
      naicsFullCodes.push(`${industryTypeCode.code}: ${industryTypeCode.industryName}`);
      naicsCodeNumbers.push(industryTypeCode.code);
      naicsCodeNames.push(industryTypeCode.industryName);
    } else if (industryTypeCode.codeSystem === 'SIC') {
      sicFullCodes.push(`${industryTypeCode.code}: ${industryTypeCode.industryName}`);
      sicCodeNumbers.push(industryTypeCode.code);
      sicCodeNames.push(industryTypeCode.industryName);
    }
  });
  return {
    naicsFullCodes,
    naicsCodeNumbers,
    naicsCodeNames,
    sicFullCodes,
    sicCodeNumbers,
    sicCodeNames,
  };
};

export const handleStateLegislators = stateLegislators => {
  let result = {};
  // assumes only one representaive and senator per notice
  stateLegislators.forEach(stateLegislator => {
    delete stateLegislator._id;
    delete stateLegislator.dob;
    delete stateLegislator.updatedAt;
    if (stateLegislator.title === 'Representative') {
      result['stateRepresentative'] = stateLegislator;
    } else if (stateLegislator.title === 'Senator') {
      result['stateSenator'] = stateLegislator;
    }
  });
  return result;
};

// Return the TCEQ public comment URL for a given notice!
export const getPublicCommentUrl = notice => {
  return `https://www14.tceq.texas.gov/epic/eComment/index.cfm?fuseaction=per.p3&PERMIT_NUM=${notice.permitNumber}&back_command=SUBMIT`;
};

// Given a notice object, return the state legislator addresses in proper address format
export const getStateLegislatorAddressesFromNotice = notice => {
  const stateLegislators = notice.stateLegislators || [];
  return getStateLegislatorAddresses(stateLegislators);
};

export const getStateLegislatorAddresses = stateLegislators => {
  return stateLegislators.map(stateLegislator => {
    return {
      title: stateLegislator.title,
      firstName: stateLegislator.givenName,
      lastName: stateLegislator.familyName,
      address: stateLegislator.address,
      addressLineOne: stateLegislator.addressLineOne,
      addressLineTwo: stateLegislator.addressLineTwo,
      city: stateLegislator.city,
      state: stateLegislator.state,
      zip: stateLegislator.zip,
    };
  });
};
