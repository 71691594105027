import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Field } from 'formik';
import closeIcon from '../assets/icons/close.svg';
import {
  ERROR,
  GREY_DARK,
  GREY_LIGHTEST,
  GREY_MEDIUM_LIGHT,
  MAX_DESKTOP_WIDTH,
} from '../constants/cssVars';
import { useDispatch, useSelector } from 'react-redux';
import {
  dateRangeSelector,
  filterValuesSelector,
  searchSelector,
  sortSelector,
} from '../selectors/notices';
import { setDateRange, setFilters, setSearch, setSort } from '../actions/notices';
import {
  CODE_SEARCHES_ARR,
  CUSTOM_RANGE,
  LAST_MONTH,
  LAST_TWO_MONTHS,
  LAST_WEEK,
  LAST_YEAR,
  NAICS_SEARCH,
  PERMIT_NUM_SEARCH,
  RN_SEARCH,
  SIC_SEARCH,
  TODAY,
} from '../constants/notices';
import DatePicker from 'react-datepicker';
import { LinkLikeButton } from './general/Button';

const SHORT_WIDTH = 300;
const EXTRA_SHORT_WIDTH = 150;

const ErrorText = styled.label`
  margin-bottom: 2px;
  font-size: 0.9em;
  display: block;
  color: ${ERROR};
  font-style: italic;
`;

const LabelledInput = styled.div`
  & > label {
    color: ${GREY_DARK};
    margin-bottom: 2px;
    padding-bottom: ${({ smallerText }) => (smallerText ? '1' : '0')}px;
    font-size: ${({ smallerText }) => (smallerText ? '0.8' : '0.9')}em;
    display: block;
    ${({ error }) => (error ? `color: ${ERROR};` : '')}
  }
  & > div > input {
    width: ${({ short, extraShort }) =>
      short ? `${SHORT_WIDTH}px` : extraShort ? `${EXTRA_SHORT_WIDTH}px` : '100%'};
  }
  & > div > select {
    width: ${({ short }) => (short ? `${SHORT_WIDTH}px` : '100%')};
  }
  margin: 5px 0px;
  margin-bottom: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0px;
  margin-bottom: 2px;
`;

const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
`;

export const DeleteButton = ({ onDelete }) => <DeleteIcon onClick={onDelete} src={closeIcon} />;

// Wrapper for field inputs, displaying labels and helper text
export const FieldWrapper = ({
  label,
  field,
  children,
  style,
  inputStyle,
  short,
  extraShort,
  error,
  onDelete,
  hideLabel,
  id,
}) => {
  const { required } = field || {};
  return (
    <LabelledInput
      style={style}
      short={short}
      extraShort={extraShort}
      error={error}
      className="fieldWrapper"
    >
      {!hideLabel && (
        <label htmlFor={id}>
          {label}
          {field && required ? '*' : ''}
        </label>
      )}
      <InputWrapper style={inputStyle}>
        {children}
        {onDelete && <DeleteButton onDelete={onDelete} />}
      </InputWrapper>
      {error && (
        <label id={`errorMsg_${id}`} style={{ fontStyle: 'italic' }}>
          {error}
        </label>
      )}
    </LabelledInput>
  );
};

export const TextField = ({ name, placeholder, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      id={name}
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      type={props.type}
      aria-invalid={props.error ? 'true' : 'false'}
    />
  </FieldWrapper>
);

export const SliderField = ({ name, placeholder, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      className="slider"
      id={name}
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      type="range"
      aria-invalid={props.error ? 'true' : 'false'}
      max={props.max}
      min={props.min}
      step={props.step}
      list={props.list}
    />
  </FieldWrapper>
);

export const ParagraphTextField = ({ name, placeholder, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      id={name}
      as="textarea"
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
    />
  </FieldWrapper>
);

export const SelectField = ({ name, children, placeholder, ...props }) => (
  <FieldWrapper {...props}>
    <Field
      id={name}
      as="select"
      name={name}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
    >
      {children}
    </Field>
  </FieldWrapper>
);

const RadioButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 13px 0px;
  margin-right: 20px;
`;

const radioInputStyle = short => ({
  backgroundColor: GREY_LIGHTEST,
  padding: '3px 8px',
  borderRadius: '0px',
  marginTop: '8px',
  border: `1px solid ${GREY_MEDIUM_LIGHT}`,
  display: 'block',
  width: short ? `${SHORT_WIDTH}px` : '100%',
  boxSizing: 'border-box',
});

export const DateRadioButtonField = ({ options, ...props }) => {
  const dispatch = useDispatch();
  const { selection, customStart, customEnd } = useSelector(dateRangeSelector);
  const today = new Date();
  const handleChange = value => () => {
    if (selection !== value) {
      if (value === TODAY) {
        dispatch(setDateRange(value, today, today));
      } else if (value === LAST_WEEK) {
        dispatch(setDateRange(value, moment().subtract(1, 'weeks').toDate(), today));
      } else if (value === LAST_MONTH) {
        dispatch(setDateRange(value, moment().subtract(1, 'months').toDate(), today));
      } else if (value === LAST_TWO_MONTHS) {
        dispatch(setDateRange(value, moment().subtract(2, 'months').toDate(), today));
      } else if (value === LAST_YEAR) {
        dispatch(setDateRange(value, moment().subtract(1, 'years').toDate(), today));
      } else {
        dispatch(setDateRange(value, customStart, customEnd));
      }
    }
  };
  const handleStartChage = startDate => dispatch(setDateRange(selection, startDate, customEnd));
  const handleEndChage = endDate => dispatch(setDateRange(selection, customStart, endDate));
  const oneYearAgoMoment = moment().subtract(1, 'years');

  return (
    <FieldWrapper {...props} inputStyle={radioInputStyle(props.short)}>
      {options.map(({ label, value }) => (
        <RadioButtonWrapper key={value}>
          <input
            style={{ width: 'auto' }}
            onChange={handleChange(value)}
            checked={value === selection}
            type="radio"
            value={value}
          />
          <div style={{ marginLeft: '5px' }}>{label}</div>
        </RadioButtonWrapper>
      ))}
      {selection === CUSTOM_RANGE && (
        <>
          <FieldWrapper
            label={`Start date (min. date is ${oneYearAgoMoment.format('MM/DD/YYYY')})`}
          >
            <DatePicker
              shouldCloseOnSelect={false}
              selected={customStart}
              onChange={handleStartChage}
              minDate={oneYearAgoMoment.toDate()}
            />
          </FieldWrapper>
          <FieldWrapper label="End date">
            <DatePicker
              shouldCloseOnSelect={false}
              selected={customEnd}
              onChange={handleEndChage}
              minDate={oneYearAgoMoment.toDate()}
            />
          </FieldWrapper>
        </>
      )}
    </FieldWrapper>
  );
};

export const SortRadioButtonField = ({ options, ...props }) => {
  const dispatch = useDispatch();
  const selectedSort = useSelector(sortSelector);
  const handleChange = value => () => {
    if (selectedSort !== value) {
      dispatch(setSort(value));
    }
  };
  return (
    <FieldWrapper {...props} inputStyle={radioInputStyle(props.short)}>
      {options.map(({ label, value }) => (
        <RadioButtonWrapper key={value}>
          <input
            style={{ width: 'auto' }}
            onChange={handleChange(value)}
            checked={value === selectedSort}
            type="radio"
            value={value}
          />
          <div style={{ marginLeft: '5px' }}>{label}</div>
        </RadioButtonWrapper>
      ))}
    </FieldWrapper>
  );
};

export const RadioButtonField = ({ name, options, fontSize, ...props }) => {
  return (
    <FieldWrapper {...props} inputStyle={radioInputStyle(props.short)}>
      {/* <div style={{ maxHeight: '300px', overflow: 'scroll' }}> */}
      {options.map(option => (
        <RadioButtonWrapper key={option.value} style={{ margin: '4px 0px' }}>
          <Field
            id={name}
            style={{ width: 'auto' }}
            type="radio"
            value={option.value}
            name={name}
            aria-describedby={`errorMsg_${name}`}
            aria-invalid={props.error ? 'true' : 'false'}
          />
          <div style={{ marginLeft: '5px', fontSize: fontSize || '0.9em' }}>{option.label}</div>
        </RadioButtonWrapper>
      ))}
      {/* </div> */}
    </FieldWrapper>
  );
};

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ wrapperStyle }) => wrapperStyle};
  margin: 10px 0px;
  margin-right: 15px;
`;

export const ReduxCheckbox = ({ value, onChange, checked, testId, label, wrapperStyle }) => {
  return (
    <CheckboxWrapper wrapperStyle={wrapperStyle}>
      <input
        id={value}
        data-testid={testId}
        onChange={onChange}
        className="regular-checkbox"
        type="checkbox"
        checked={checked}
        value={value}
      />
      {label}
    </CheckboxWrapper>
  );
};

const Checkbox = ({ name, value, error, testId, label, children, wrapperStyle }) => (
  <CheckboxWrapper wrapperStyle={wrapperStyle}>
    <Field
      id={name}
      data-testid={testId}
      className="regular-checkbox"
      type="checkbox"
      name={name}
      value={value}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={error ? 'true' : 'false'}
    />
    {children}
    {label}
  </CheckboxWrapper>
);

export const CheckboxField = ({ error, ...props }) => (
  <div>
    <Checkbox {...props} />
    {error && <ErrorText>{error}</ErrorText>}
  </div>
);

const LightGreyWrapper = styled.div`
  background-color: ${GREY_LIGHTEST};
  padding: 3px 8px;
  border-radius: 0px;
  margin-top: 8px;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
`;

const ClearSelectWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// FOR FILTERS
export const FilterCheckboxGroup = ({
  checkboxOptions,
  name,
  error,
  labelId,
  label,
  style,
  checkboxStyle,
}) => {
  const dispatch = useDispatch();
  const filterObj = useSelector(state => filterValuesSelector(state, name));
  const handleChange = (value, isChecked) => () => {
    dispatch(setFilters(name, value, !isChecked));
  };
  // used to clear all or select all checkboxes
  const setAllCheckboxes = checked => {
    checkboxOptions.forEach(checkbox => {
      dispatch(setFilters(name, checkbox.value, checked));
    });
  };
  return (
    <LabelledInput error={error}>
      <label id={labelId}>{label}</label>
      <LightGreyWrapper
        role="group"
        aria-labelledby={labelId}
        style={style}
        aria-describedby={`errorMsg_${name}`}
        aria-invalid={error ? 'true' : 'false'}
      >
        {checkboxOptions.map(props => {
          const checked = filterObj[props.value];
          return (
            <ReduxCheckbox
              {...props}
              checked={checked}
              onChange={handleChange(props.value, checked)}
              wrapperStyle={checkboxStyle}
              key={props.value}
            />
          );
        })}
        <ClearSelectWrapper>
          <LinkLikeButton onClick={() => setAllCheckboxes(true)} style={{ fontSize: '0.9em' }}>
            select all
          </LinkLikeButton>
          <LinkLikeButton
            onClick={() => setAllCheckboxes(false)}
            style={{ fontSize: '0.9em', marginLeft: '10px' }}
          >
            clear all
          </LinkLikeButton>
        </ClearSelectWrapper>
      </LightGreyWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </LabelledInput>
  );
};

export const CheckboxGroup = ({
  checkboxOptions,
  name,
  error,
  labelId,
  label,
  style,
  checkboxStyle,
}) => (
  <LabelledInput error={error}>
    <label id={labelId}>{label}</label>
    <LightGreyWrapper
      role="group"
      aria-labelledby={labelId}
      style={style}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={error ? 'true' : 'false'}
    >
      {checkboxOptions.map(props => (
        <Checkbox {...props} name={name} wrapperStyle={checkboxStyle} key={props.value} />
      ))}
    </LightGreyWrapper>
    {error && <ErrorText>{error}</ErrorText>}
  </LabelledInput>
);

export const MultiFieldLine = styled.div`
  width: 100%;
  width: calc(100% - 15px);
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: ${({ columns }) => columns};
  align-items: start;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    > div {
      max-width: 400px;
    }
  }
`;

// Input group to filter by code (NAICS, SIC, RN, Permit Number)
export const CodeFilterGroup = () => {
  const dispatch = useDispatch();
  const searches = useSelector(searchSelector);
  const naicsSearchStr = searches[NAICS_SEARCH];
  const sicSearchStr = searches[SIC_SEARCH];
  const rnSearchStr = searches[RN_SEARCH];
  const permitNumStr = searches[PERMIT_NUM_SEARCH];
  const handleChange = (type, value) => {
    dispatch(setSearch(type, value));
  };
  // Clear all of the inputs
  const clearAllInputs = () => {
    CODE_SEARCHES_ARR.forEach(codeType => {
      dispatch(setSearch(codeType, ''));
    });
  };
  return (
    <LabelledInput>
      <label>Permit Number, RN, & Industry Type Codes</label>
      <LightGreyWrapper>
        <LabelledInput>
          <label id="permitNum">Permit Number</label>
          <input
            aria-labelledby="permitNum"
            value={permitNumStr}
            onChange={e => handleChange(PERMIT_NUM_SEARCH, e.target.value)}
          />
        </LabelledInput>
        <LabelledInput>
          <label id="rnNumber">RN Number</label>
          <input
            aria-labelledby="rnNumber"
            value={rnSearchStr}
            onChange={e => handleChange(RN_SEARCH, e.target.value)}
          />
        </LabelledInput>
        <LabelledInput>
          <label id="naicsCode">NAICS Code</label>
          <input
            aria-labelledby="naicsCode"
            value={naicsSearchStr}
            onChange={e => handleChange(NAICS_SEARCH, e.target.value)}
          />
        </LabelledInput>
        <LabelledInput>
          <label id="sicCode">SIC Code</label>
          <input
            aria-labelledby="sicCode"
            value={sicSearchStr}
            onChange={e => handleChange(SIC_SEARCH, e.target.value)}
          />
        </LabelledInput>
        <ClearSelectWrapper>
          <LinkLikeButton
            onClick={clearAllInputs}
            style={{ fontSize: '0.9em', marginLeft: '10px' }}
          >
            clear all
          </LinkLikeButton>
        </ClearSelectWrapper>
      </LightGreyWrapper>
    </LabelledInput>
  );
};

export const OptionsList = ({ hideSelectOne, options, name }) => {
  const fullOptions = [...(hideSelectOne ? [] : [{ value: '', label: 'Select One' }]), ...options];
  return fullOptions.map(option => (
    <option key={`${name}_${option.value}`} value={option.value}>
      {option.label}
    </option>
  ));
};
