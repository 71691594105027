import React from "react";
import styled from "styled-components";
import { Route, Routes, Navigate } from "react-router-dom";
import { Footer } from "./Footer";
import { Flip, ToastContainer } from "react-toastify";
import { Login } from "./Login";
import { AppRoot } from "./app/AppRoot";
import { ResetPassword } from "./ResetPassword";
import { EmailSubscriptions } from "./app/EmailSubscriptions";

const Wrapper = styled.div`
  width: 100%;
`;

export const Main = () => {
  return (
    <Wrapper>
      <ToastContainer
        autoClose={3000}
        position="bottom-right"
        transition={Flip}
        hideProgressBar
      />
      <main>
        <Routes>
          <Route path="login" exact={true} element={<Login />} />
          <Route
            path="reset-password/:resetPasswordToken"
            element={<ResetPassword />}
          />
          <Route path=":aahOrApp/*" element={<AppRoot />} />
          <Route path="/*" element={<Navigate to="/app" />} />
          <Route
            path="email-preferences/:subscriptionId"
            element={<EmailSubscriptions />}
          />
        </Routes>
      </main>
      {/* <Footer /> */}
      {/* Show the footer on the landing and login pages only */}
      <Routes>
        <Route path="login" exact={true} element={<Footer />} />
        <Route
          path="reset-password/:resetPasswordToken"
          element={<ResetPassword />}
        />
      </Routes>
    </Wrapper>
  );
};
