import React, { useEffect } from 'react';
import { Route, Navigate, Routes, useNavigate, useParams } from 'react-router-dom';
import { useRequest } from 'redux-query-react';
import styled from 'styled-components';
import { PageLoader } from '../general/Loaders';
import {
  getPermitNoticesQuery,
  getCurrentUserQuery,
  getAccountInfoQuery,
} from '../../actions/queries';
import SessionExpirationTimer from '../general/SessionExpirationTimer';
import { useDispatch, useSelector } from 'react-redux';
import { accountInfoSelector, currentUserSelector } from '../../selectors/entities';
import { AllNoticesSidebar } from './AllNoticesSidebar';
import { NoticeDetailsSidebar } from './NoticeDetailsSidebar';
import { Header, HeaderOffset } from '../Header';
import { Map } from './Map';
import { useCurrentNotice } from '../../hooks/useCurrentNotice';
import { SettingsSidebar } from './SettingsSidebar';
import { UserManagement } from './UserManagement';
import { CloseActiveDropdown } from '../general/CloseActiveDropdown';
import { LEFT_SIDEBAR_WIDTH, MAX_DESKTOP_WIDTH } from '../../constants/cssVars';
import { NewUserModal } from './NewUserModal';
import { setCounty } from '../../actions/notices';
import { countySelector } from '../../selectors/notices';
import { AboutUsModal } from './AboutUsModal';
import { EmailSubscribeModal } from './EmailSubscribeModal';
import { CreateCampaignModal } from './CreateCampaignModal';
import { StannpSlider } from './StannpSlider';
import { Account } from './Account';
import { IndustryTypeCodesModal } from './IndustryTypeCodesModal';
import { pinDroppedSelector } from '../../selectors/general.js';
import { PinSidebar } from './PinSidebar';
import { CustomAddressesModal } from './CustomAddressesModal';
import { EmailSubscriptionManagement } from './EmailSubscriptionManagement';
import { LearnMoreModal } from './LearnMoreModal';
import { YourEmailSubscriptions } from './YourEmailSubscriptions';
import { useSyncNoticeFilterParams } from '../../hooks/useSyncNoticeFilterParams';
import { EditNoticeModal } from './EditNoticeModal';

// Root component for all of the app pages.
export const AppRoot = () => {
  const [{ isFinished }] = useRequest(getCurrentUserQuery());
  const currentUser = useSelector(currentUserSelector);
  const selectedCounty = useSelector(countySelector);
  const dispatch = useDispatch();
  const { aahOrApp } = useParams();
  const generalAppDisplay = aahOrApp === 'app';
  const aahEmbedDisplay = aahOrApp === 'aah';
  const navigate = useNavigate();

  // If current user is loaded, set the selected county to the user's county
  useEffect(() => {
    if (currentUser != null && currentUser.county && selectedCounty != currentUser.county) {
      dispatch(setCounty(currentUser.county));
    }
  }, [currentUser]);

  // Redirect back to "/app" if the root app path doesn't include "app" or "aah"
  useEffect(() => {
    if (!generalAppDisplay && !aahEmbedDisplay) {
      navigate('/app');
    }
  }, [generalAppDisplay, aahEmbedDisplay]);

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
    <div>
      {generalAppDisplay && (
        <>
          <NewUserModal />
          <AboutUsModal />
          <IndustryTypeCodesModal />
          <CreateCampaignModal />
          <CustomAddressesModal />
          <EmailSubscribeModal />
          <LearnMoreModal />
          <EditNoticeModal />
        </>
      )}
      <Routes>
        <Route path=":routeId/*" element={<Header countyNavOnly={aahEmbedDisplay} />} />
      </Routes>
      <HeaderOffset />
      <div>
        <Routes>
          <Route path="notices" element={<MapRoot />} />
          {currentUser && generalAppDisplay && (
            <Route path="settings/*" element={<SettingsRoot />} />
          )}
          <Route path="/*" element={<Navigate to="notices" />} />
        </Routes>
      </div>
      {generalAppDisplay && <SessionExpirationTimer />}
      <CloseActiveDropdown />
    </div>
  );
};

const SettingsWrapper = styled.div`
  padding-left: ${LEFT_SIDEBAR_WIDTH}px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding-left: 0px;
  }
`;

const SettingsRoot = () => {
  const currentUser = useSelector(currentUserSelector);
  const { firstName, lastName, email, county } = useSelector(accountInfoSelector);
  const [{ isFinished }] = useRequest(getAccountInfoQuery());

  const fullName = `${firstName} ${lastName}`;

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
    <div style={{ padding: '30px' }}>
      <SettingsSidebar />
      <SettingsWrapper>
        <Routes>
          {currentUser && currentUser.admin && (
            <Route path="users" exact={true} element={<UserManagement />} />
          )}
          <Route
            path="account"
            exact={true}
            element={<Account fullName={fullName} userEmail={email} primaryCounty={county} />}
          />
          <Route
            path="your-email-subscriptions"
            exact={true}
            element={<YourEmailSubscriptions userEmail={email} />}
          />
          <Route
            path="email-subscription-management"
            exact={true}
            element={<EmailSubscriptionManagement />}
          />
          <Route path="/*" element={<Navigate to="account" />} />
        </Routes>
      </SettingsWrapper>
    </div>
  );
};

const MapRoot = () => {
  const currentNotice = useCurrentNotice();
  const [{ isFinished, status }] = useRequest(getPermitNoticesQuery());
  const currentUser = useSelector(currentUserSelector);
  // We only want to render the Stannp Slider for non-admin logged in users
  const showStannpSlider = currentUser && !currentUser.admin;

  // Get the info on whether there's a pin on the map
  const pinDropped = useSelector(pinDroppedSelector);
  let sidebar;

  // Hook responsible for syncing search param filters with the store
  useSyncNoticeFilterParams();

  if (!isFinished) {
    return <PageLoader />;
  }

  if (pinDropped) {
    sidebar = <PinSidebar />;
  } else if (currentNotice) {
    sidebar = <NoticeDetailsSidebar />;
  } else {
    sidebar = <AllNoticesSidebar />;
  }

  return (
    <div>
      {sidebar}
      {showStannpSlider && <StannpSlider />}
      <Map />
    </div>
  );
};
